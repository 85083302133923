import { createRouter, createWebHistory } from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import ChangePassword from '../views/account/ChangePassword.vue'
import Masterdata from '../views/Masterdata.vue'
import OrganisationUsers from '../views/OrganisationUsers.vue'
import OrganisationReportUsers from '../views/OrganisationReportUsers.vue'
import ConfigOrganisationDailyReport from '../views/ConfigOrganisationDailyReport.vue'
import ConfigOrganisationDailyReportMail from '../views/ConfigOrganisationDailyReportMail.vue'
import OrganisationLicenses from '../views/OrganisationLicenses.vue'
import ConfigLiveDatas from '../views/ConfigLiveDatas.vue'
import Plants from '../views/Plants.vue'
import Evaluation from '../views/Evaluation.vue'
import EditLimits from '../views/EditLimits.vue'
import AlarmcodesGeneral from '../views/AlarmcodesGeneral.vue'
import ConfigPlantJournals from '../views/ConfigPlantJournals.vue'
import ConfigPlantDashboards from '../views/ConfigPlantDashboards.vue'
import AlarmcodesOrganisation from '../views/AlarmcodesOrganisation.vue'
import Analyse from '../views/Analyse.vue'
import Research from '../views/Research.vue'
import OrganisationDailyReport from '../views/OrganisationDailyReport.vue'
import PlantJournals from '../views/PlantJournals.vue'
import AlarmmessagesForPlants from '../views/AlarmmessagesForPlants.vue'
import PlantEvaluationsForPlants from '../views/PlantEvaluationsForPlants.vue'
import LiveData from '../views/LiveData.vue'
import Organisations from '../views/Organisations.vue'
import ActivityLogs from '../views/ActivityLogs.vue'
import TriggerBackendTasks from '../views/TriggerBackendTasks.vue'
import CreateNewPlant from '../views/CreateNewPlant.vue'
import PlantNotifications from '../views/PlantNotifications.vue'
import PlantNotificationParks from '../views/PlantNotificationParks.vue'
import OrganisationPriceOverview from '../views/OrganisationPriceOverview.vue'
import Organisation from '../views/Organisation.vue'
import Permissions from '../views/Permissions.vue'
import ActivateAccount from '../views/account/ActivateAccount.vue'
import ResetPassword from '../views/account/ResetPassword.vue'
import AddOrganisation from '../views/AddOrganisation.vue'
import UserGroups from '../views/UserGroups.vue'
import LogIn from '../views/LogIn.vue'
import PlantSystems from '../views/PlantSystems.vue'
import PlantTypes from '../views/PlantTypes.vue'
import PlantTypeEvaluations from '../views/PlantTypeEvaluations.vue'

import store from '../store'

const routes = [
  {
    path: '/evaluation/:type', 
    name: 'Evaluation',
    component: Evaluation
  },
  {
    path: '/',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/account/activate/:uid/:token',
    name: 'ActivateAccount',
    component: ActivateAccount
  },
  {
    path: '/account/change-password',
    name: 'ChangePassword',
    component: ChangePassword
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/agb',
    name: 'AGB',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Agb.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue')
  },
  {
    path: '/licence',
    name: 'Lizenz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Licence.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue')
  },
  {
    path: '/account/password_reset/:uid/:token',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/masterdata',
    name: 'Masterdata',
    component: Masterdata,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/users',
    name: 'OrganisationUsers',
    component: OrganisationUsers,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/report_users',
    name: 'OrganisationReportUsers',
    component: OrganisationReportUsers,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/daily_report',
    name: 'ConfigOrganisationDailyReport',
    component: ConfigOrganisationDailyReport,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/daily_report_mail',
    name: 'ConfigOrganisationDailyReportMail',
    component: ConfigOrganisationDailyReportMail,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/organisation_licenses',
    name: 'OrganisationLicenses',
    component: OrganisationLicenses,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/plants',
    name: 'Plants',
    component: Plants,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/edit_limits',
    name: 'EditLimits',
    component: EditLimits,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/usergroups',
    name: 'UserGroups',
    component: UserGroups,
    meta: {
      requireLogin: true,
      user_group_can_read: true
    }
  },
  {
    path: '/settings/live_datas',
    name: 'ConfigLiveDatas',
    component: ConfigLiveDatas,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/live-data',
    name: 'LiveData',
    component: LiveData,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/analyse',
    name: 'Research',
    component: Research,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/research',
    name: 'Analyse',
    component: Analyse,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/organisation_daily_report',
    name: 'OrganisationDailyReport',
    component: OrganisationDailyReport,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/plant_journals',
    name: 'PlantJournals',
    component: PlantJournals,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/alarmmessages_for_plants',
    name: 'AlarmmessagesForPlants',
    component: AlarmmessagesForPlants,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/plant_evaluations_for_plants',
    name: 'PlantEvaluationsForPlants',
    component: PlantEvaluationsForPlants,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/settings/masterdata/organisations',
    name: 'Organisations',
    component: Organisations,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/masterdata/plantsystems',
    name: 'PlantSystems',
    component: PlantSystems,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/masterdata/planttypes',
    name: 'PlantTypes',
    component: PlantTypes,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/masterdata/planttypeevaluations',
    name: 'PlantTypeEvaluations',
    component: PlantTypeEvaluations,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/admin/logs',
    name: 'ActivityLogs',
    component: ActivityLogs,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/admin/trigger_backend_tasks',
    name: 'TriggerBackendTasks',
    component: TriggerBackendTasks,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/admin/create_new_plant',
    name: 'CreateNewPlant',
    component: CreateNewPlant,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/billing/plant_notifications',
    name: 'PlantNotifications',
    component: PlantNotifications,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/billing/plant_notification_parks',
    name: 'PlantNotificationParks',
    component: PlantNotificationParks,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/billing/organisation_price_overview',
    name: 'OrganisationPriceOverview',
    component: OrganisationPriceOverview,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/alarmcodesgeneral',
    name: 'AlarmcodesGeneral',
    component: AlarmcodesGeneral,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/config_plant_journals',
    name: 'ConfigPlantJournals',
    component: ConfigPlantJournals,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/settings/config_plant_dashboards',
    name: 'ConfigPlantDashboards',
    component: ConfigPlantDashboards,
    meta: {
      requireLogin: true,
    }
  },
  {
    path: '/settings/alarmcodesorganisation',
    name: 'AlarmcodesOrganisation',
    component: AlarmcodesOrganisation,
    meta: {
      requireLogin: true,
      alarmcodes_can_read: true
    }
  },
  {
    path: '/settings/permissions',
    name: 'Permissions',
    component: Permissions,
    meta: {
      requireLogin: true,
      permissions_can_read: true
    }
  },
  {
    path: '/settings/masterdata/organisations/:id',
    name: 'Organisation',
    component: Organisation,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/settings/masterdata/organisations/add',
    name: 'AddOrganisation',
    component: AddOrganisation,
    meta: {
      requireLogin: true,
      isSuperuser: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {
          el: to.hash,
          behavior: 'smooth'
        }
    } else {
        return { top: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next('/')
  } else {
    if ((to.matched.some(record => record.meta.isSuperuser) && !store.state.me.is_superuser)
    ||(to.matched.some(record => record.meta.user_group_can_read) && !store.getters.userHasPermission('user_group', 'can_read'))
    ||(to.matched.some(record => record.meta.alarmcodes_can_read) && !store.getters.userHasPermission('alarmcodes', 'can_read'))
    ||(to.matched.some(record => record.meta.permissions_can_read) && !store.getters.userHasPermission('permission', 'can_read'))) {
      next('/dashboard')
    } 
    else {
      next()
    }
  }
  
})

export default router
